import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { Observable } from "rxjs";
import { FormControl } from "@angular/forms";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { map, startWith } from "rxjs/operators";
import { ChipType } from "./chip-type.enum";
import { SystemTypes } from "../system-filter/system-filter.component";
import { Tag } from "../../../../../codegen/out/types";

@Component({
  selector: "app-tag-select",
  templateUrl: "./tag-select.component.html",
  styleUrls: ["./tag-select.component.scss"],
})
export class TagSelectComponent implements OnInit, OnChanges {
  @Input() allAvailableTags: Tag[];
  filteredTags: Observable<string[]>;
  formControl = new FormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  bgClass: string = "bg-included";

  @ViewChild("tagInput") tagInput: ElementRef<HTMLInputElement>;

  @Input() title: string;
  @Input() selectedTags: string[] = [];
  @Input() selectedTypes: string[] = [];
  @Input() chipType: ChipType = ChipType.INCLUDED;
  @Output() updated = new EventEmitter<string[]>();

  constructor() {}

  ngOnInit() {
    if (this.chipType === ChipType.EXCLUDED) {
      this.bgClass = "bg-excluded";
    } else {
      this.bgClass = "bg-included";
    }

    this.updateFormControl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateFormControl();
  }

  private updateFormControl(): void {
    this.filteredTags = this.formControl.valueChanges.pipe(
      startWith(""),
      map((value) => this.filterTagsBasedOnTypes(value, this.selectedTypes))
    );
  }

  private filterTagsBasedOnTypes(
    value: any,
    selectedSystemTypes: string[]
  ): string[] {
    const filterValue = value.toLowerCase();
    return this.allAvailableTags
      .filter((tag) => tag.name.toLowerCase().includes(filterValue))
      .map((tag) => tag.name);
  }

  private getDomainClassForSystemType(systemType: string) {
    return SystemTypes.find((type) => type.value == systemType).domainClass;
  }

  add(event: MatChipInputEvent): void {
    if (this.addSelectedTag(event.value)) {
      event.input.value = "";
      this.formControl.setValue("");
      this.triggerUpdate();
    }
  }

  remove(tagToRemove: string): void {
    this.selectedTags = this.selectedTags.filter((tag) => tag !== tagToRemove);
    this.triggerUpdate();
  }

  private addSelectedTag(tag: string): boolean {
    if (
      !this.allAvailableTags.find((tagModel) => tagModel.name == tag) ||
      this.selectedTags.includes(tag)
    ) {
      return false;
    }
    this.selectedTags.push(tag);
    return true;
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (this.addSelectedTag(event.option.value)) {
      this.tagInput.nativeElement.value = "";
      this.formControl.setValue("");
      this.triggerUpdate();
    }
  }

  private triggerUpdate() {
    this.updated.emit(this.selectedTags);
  }
}
